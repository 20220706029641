import React from 'react';
import styled, { css } from 'styled-components';
import { Experience } from '@ninetailed/experience.js-gatsby';
import { ExperienceMapper } from '@ninetailed/experience.js-utils';

import { getComponentSettings } from 'libs/content';
import useWindow from 'context/useWindow';
import Loading from 'components/Loading';
import MaxWidth from 'layouts/max-width';
import LazyImage from 'components/LazyImage';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Spacing from 'layouts/Spacing';
import { ComponentWrapperStyle } from 'components/ComponentWrapper';
import SanitizeHtml from 'libs/SanitizeHtml';

const Background = styled.div`
	${ComponentWrapperStyle}
	position: relative;
	padding: ${p =>
		`calc(${p.theme.spacing.desktop.large} + ${p.theme.spacing.desktop.small}) 0`};
	${p =>
		p.theme.media.mediumOnly(css`
			padding: ${p =>
				`calc(${p.theme.spacing.tablet.large} + ${p.theme.spacing.tablet.small}) 0`};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			padding: ${p =>
				`calc(${p.theme.spacing.mobile.large} + ${p.theme.spacing.mobile.small}) 0`};
		`)}

	${p =>
		!!p?.$spacing?.bottom &&
		css`
			padding-bottom: ${p.theme.spacing.desktop[p.$spacing.bottom]};
			${p.theme.media.mediumOnly(css`
				padding-bottom: ${p.theme.spacing.tablet[p.$spacing.bottom]};
			`)}
			${p.theme.media.smallOnly(css`
				padding-bottom: ${p.theme.spacing.mobile[p.$spacing.bottom]};
			`)}
		`}

	// If the component is centered, change the max-width
	${p =>
		p.$centered === 'true' &&
		css`
			.max-width {
				max-width: ${p => p.theme.widths.medium};
			}
		`}
`;

const Wrapper = styled.div`
	.spacing {
		margin-top: 0;
	}

	&.has-bg-color {
		.component__topbanner--text {
			${p =>
				p.theme.media.smallOnly(css`
					padding-top: 0 !important;
					margin-top: 0 !important;
				`)}
		}
	}

	// When has no background-color, remove padding-bottom for spacing-wrapper
	&:not(.has-bg-color) {
		.spacing {
			padding-bottom: 0;
			${p =>
				p.theme.media.mediumOnly(css`
					padding-bottom: 0;
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					padding-bottom: 0;
				`)}
		}
	}

	// When has no background-color and has image, or no image and background-color, remove padding-top and margin-top for spacing-wrapper
	&.has-image:not(.transparent-header):not(.has-bg-color) .spacing,
	&:not(.has-image):not(.has-bg-color) .spacing {
		padding-top: 0;
		margin-top: 0;
		${p =>
			p.theme.media.mediumOnly(css`
				padding-top: 0;
				margin-top: 0;
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				padding-top: 0;
				margin-top: 0;
			`)}
	}

	// Has image and no background-color and no breadcrumbs, remove padding-top for background
	&.has-image:not(.transparent-header):not(.has-bg-color) {
		${Background} {
			${p =>
				p.theme.media.smallOnly(css`
					padding: ${p => `0 0 ${p.theme.spacing.mobile.xlarge} 0`};
				`)}
		}
	}

	// When next component/section in DOM should float (from below) over this topbanner
	&.next-component-float {
		margin-bottom: ${p =>
			`calc(-${p.theme.spacing.desktop.xlarge} - ${p.theme.spacing.tablet.large})`};
		${p =>
			p.theme.media.mediumOnly(css`
				margin-bottom: ${p =>
					`calc(-${p.theme.spacing.tablet.xlarge} - ${p.theme.spacing.tablet.large})`};
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: ${p =>
					`calc(-${p.theme.spacing.mobile.xlarge} - ${p.theme.spacing.mobile.medium})`};
				&.error {
					margin-bottom: 0;
				}
			`)}
	}

	// Different padding-top when has transparent header (minus height of header)
	&.transparent-header:not(.next-component-float) {
		${Background} {
			${p =>
				p.theme.media.large(css`
					padding-top: ${p =>
						`calc(${p.theme.spacing.desktop.large} + ${p.theme.spacing.desktop.small} + 100px)`};
				`)}
			${p =>
				p.theme.media.mediumOnly(css`
					padding-top: ${p =>
						`calc(${p.theme.spacing.tablet.large} + ${p.theme.spacing.tablet.small} + 100px)`};
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					padding-top: ${p =>
						`calc(${p.theme.spacing.mobile.large} + 60px)`};
				`)}
		}
		&.has-breadcrumbs ${Background} {
			${p =>
				p.theme.media.smallOnly(css`
					padding-top: ${p =>
						`calc(${p.theme.spacing.desktop.large} + 60px)`} !important;
				`)}
		}
	}

	// Different padding-top for background-color and transparent header or next-component-float
	&.has-bg-color.transparent-header.next-component-float,
	&.next-component-float {
		${Background} {
			${p =>
				p.theme.media.large(css`
					padding-top: ${p =>
						`calc(${p.theme.spacing.desktop.xlarge} + 70px)`};
				`)}
			${p =>
				p.theme.media.mediumOnly(css`
					padding-top: ${p =>
						`calc(${p.theme.spacing.tablet.xlarge} + 70px)`};
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					padding-top: ${p =>
						`calc(${p.theme.spacing.mobile.xlarge} + 70px)`};
				`)}
		}
		// When has breadcrumbs, increase padding-top with breadcrumbs height
		&.has-breadcrumbs {
			${Background} {
				${p =>
					p.theme.media.large(css`
						padding-top: ${p =>
							`calc(${p.theme.spacing.desktop.xlarge} + 70px + 57px)`};
					`)}
				${p =>
					p.theme.media.mediumOnly(css`
						padding-top: ${p =>
							`calc(${p.theme.spacing.tablet.xlarge} + 70px + 57px)`};
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						padding-top: ${p =>
							`calc(${p.theme.spacing.mobile.xlarge} + 70px + 48px)`};
					`)}
			}
		}
	}
`;

const TextWrap = styled.div`
	${p =>
		p.theme.media.smallOnly(css`
			width: 100%;
			margin-top: ${p => p.theme.spacing.mobile.small};
			padding-top: ${p => p.theme.spacing.mobile.large};
		`)}
	${p =>
		p.theme.media.medium(css`
			flex: 1 1 calc(7 / 12 * 100%);
			padding-right: 20px;
		`)}
	.text {
		margin-bottom: 0;
	}
`;

const ImageWrap = styled.div`
	margin-top: 30px;
	> div {
		text-align: center;
	}
	${p =>
		p.theme.media.medium(css`
			flex: 1 1 calc(5 / 12 * 100%);
			padding-left: 20px;
			margin-top: 0;
			.lazy-image {
				width: fit-content;
				margin-left: auto;
			}
		`)}
`;

const Wrap = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			align-items: center;
		`)}

	${p =>
		(p.$hasImage &&
			css`
				.medium-width {
					padding-left: 0;
				}
				${p =>
					p.theme.media.smallOnly(css`
						display: flex;
						align-items: center;
						flex-direction: column;

						${ImageWrap} {
							margin: 45px -40px 0;
							.lazy-image,
							img {
								border-radius: 0 !important;
							}
						}
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						${ImageWrap} {
							margin: 45px -25px 0;
						}
					`)}
			`) ||
		css`
			.medium-width {
				max-width: ${p => p.theme.widths.medium};
				margin: 0;
			}
		`}
`;

/**
 * Represents a top banner
 * @param {string} headinglevel - The heading level for the title of the component
 * @param {boolean} isfirstheading - Whether the heading is the first one on the page
 * @param {object} loading - The loading properties
 * @param {...object} props - The rest of the properties for the component
 */
export default function NinetailedTopBanner({ ...props }) {
	try {
		if (!props?.experiences?.length) throw new Error('No experiences');
		props.id = props.contentful_id;

		const mappedExperiences = props?.experiences
			?.filter(ExperienceMapper.isExperienceEntry)
			?.map(ExperienceMapper.mapExperience);

		return (
			<Experience
				{...props}
				component={TopBanner}
				experiences={mappedExperiences}
			/>
		);
	} catch (error) {
		return <TopBanner {...props} />;
	}
}

/**
 * Represents a top banner, with a title, optional sub text, optional buttons and an optional image
 *
 * @param {array} settings - The settings for the component
 * @param {object} image - The image for the component
 * @param {object} pagesettings - The page settings
 * @param {boolean} showLoading - Whether to show loading
 * @param {string} className - The class name
 * @param {...object} props - The rest of the properties for the component
 * @returns {JSX.Element} The top banner
 *
 * Rules:
 * - Title is required
 * - Stick title is optional and placed above the title
 * - SubText is optional
 * - Buttons are optional
 * - Image is optional, always placed to the right of the title and text, can be hidden on mobile screens
 * - If the image is an SVG, it will be rendered as HTML
 * - Text elements and buttons can be centered, but preferably left-aligned
 * - The background can be transparent, or have a color (Blå 100, Grønn 200, Blå 800, Korall 100)
 * - Settings can be used to hide the image on mobile screens, show app download buttons, and float the next component over the top banner
 *
 **/
export function TopBanner({ settings = [], ...props }) {
	const { windowWidth } = useWindow();
	const isMobile = windowWidth < 768;

	// Get component settings
	const componentSettings = getComponentSettings({ settings, props });

	// Define banner properties
	const bannerProps = {
		...props,
		image:
			(isMobile &&
				componentSettings?.hidemediaonmobile === 'true' &&
				false) ||
			(props?.image?.file?.url && {
				url: props?.image?.file?.url,
				svg: props?.image?.svg,
				alt:
					props?.image?.description ||
					props?.image?.title ||
					props?.title,
				...props?.image,
			}) ||
			undefined,
	};

	// Define classes
	let classes = `component__topbanner ${props?.className || ''}`;
	if (!!bannerProps?.image) {
		classes += ' has-image';
	}
	if (componentSettings?.bg && componentSettings?.bg !== 'transparent') {
		classes += ' has-bg-color';
	}
	if (
		!bannerProps?.pagesettings?.hidebreadcrumbs ||
		bannerProps?.pagesettings?.hidebreadcrumbs !== 'true'
	) {
		classes += ' has-breadcrumbs';
	}
	if (componentSettings?.nextcomponentfloat === 'true') {
		classes += ' next-component-float';
	}
	if (bannerProps?.pagesettings?.transparentheader === 'true') {
		classes += ' transparent-header';
	}

	return (
		<Wrapper data-cy="component__topbanner" className={classes}>
			<Spacing {...props}>
				<Background
					{...componentSettings}
					$spacing={props?.spacing}
					$centered={componentSettings?.centered}
					className="component__topbanner--bg">
					<MaxWidth className="max-width">
						<Wrap
							$hasImage={
								!!bannerProps?.image &&
								componentSettings?.hidemediaonmobile !== 'true'
							}
							className="component__topbanner--wrap">
							<TextWrap className="component__topbanner--text">
								{(props?.showLoading && (
									<Loading {...props} />
								)) || (
									<TitleAndText
										{...props}
										text={props?.subText}
										buttons={
											componentSettings?.showappdownloadbtns ===
											'true'
												? []
												: props?.buttons
										}
										headinglevel="h1"
										isfirstheading={true}
										nested={true}
										settings={settings}
										margin="0"
									/>
								)}
							</TextWrap>

							{(isMobile &&
								componentSettings?.hidemediaonmobile ===
									'true' && <></>) ||
								(!!bannerProps?.image && (
									<ImageWrap className="component__topbanner--image">
										{(bannerProps?.image?.svg
											?.originalContent && (
											<SanitizeHtml
												html={
													bannerProps?.image?.svg
														?.originalContent
												}
											/>
										)) || (
											<LazyImage
												{...bannerProps?.image}
												ratio={
													componentSettings?.imagecrop ||
													'16:10'
												}
											/>
										)}
									</ImageWrap>
								))}
						</Wrap>
					</MaxWidth>
				</Background>
			</Spacing>
		</Wrapper>
	);
}
